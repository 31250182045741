<template>
  <div class="login-wrapper">
    <div class="flex justify-center px-6 mb-10">
      <Logo class="h-8" />
    </div>
    <div class="mb-2">
      <TextInput id="email"
                 type="email"
                 label="Email"
                 placeholder="Email address..."
                 v-model="potentialUser.email"
                 @keyup.enter.native="handleLogin"/>
    </div>
    <div class="mb-2">
      <TextInput id="password"
                 type="password"
                 label="Password"
                 placeholder="***********"
                 v-model="potentialUser.password"
                 @keyup.enter.native="handleLogin" />
    </div>
    <div class="mb-8">
      <router-link
        :to="{ name: 'forgot-password' }"
        class="text-gray-200 text-sm uppercase outline-none
               hover:text-white focus:text-white
               transition-colors duration-200 ease-in">
        Forgot your password?
      </router-link>
    </div>
    <Button
      type="primary"
      :inFlight="isLoading"
      @onClick="handleLogin">
      Login
    </Button>
    <div v-if="globalErrors.length" class="mt-8">
      <ErrorMessage
        v-for="(error, index) in globalErrors"
        :key="`error${index}`"
        :error="error">
      </ErrorMessage>
    </div>
    <div class="mt-12 py-3 px-6 bg-gray-900 border border-gray-500 text-center">
      <p class="text-sm">Don't have an account?</p>
      <router-link
        :to="{ name: 'signup' }"
        class="text-gray-200 text-sm uppercase outline-none
               hover:text-white focus:text-white
               transition-colors duration-200 ease-in">
        Sign up
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Logo from '@/assets/orbital_logo.svg?inline';
import TextInput from '@/components/TextInput.vue';
import Button from '@/components/Button.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
  name: 'Login',
  components: {
    ErrorMessage,
    Logo,
    TextInput,
    Button,
  },
  computed: {
    ...mapGetters(['globalErrors', 'isLoading']),
  },
  data() {
    return {
      requiredFields: ['email', 'password'],
      potentialUser: {
        email: '',
        password: '',
      },
    };
  },
  created() {
    this.clearGlobalErrors();
  },
  methods: {
    ...mapActions(['login', 'clearGlobalErrors']),
    handleLogin() {
      this.invalidFields = this.requiredFields.filter((field) => !this.potentialUser[field]);
      if (this.invalidFields.length > 0) {
        return this.$store.commit('UPDATE_GLOBAL_ERRORS', ['Please include all fields']);
      }
      return this.login(this.potentialUser);
    },
  },
};
</script>

<style scoped>
.login-wrapper {
  @apply mt-32 mx-auto w-full;
  max-width: 300px;
}
</style>
