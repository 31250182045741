<template>
  <transition name="slide-down" mode="out-in">
    <div class="flex justify-center items-center h-screen">
      <div class="flex-col">
        <img v-if="qrCode" :src="'data:image/png;base64,' + qrCode" alt="QR Code" />
        <p class="my-6 mx-auto">
          scan this using google authenticator, duo, or your favorite totp mobile app
        </p>
        <Button type="primary" class="text-gray-300" @onClick="nextStage">
          Continue
        </Button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';
import Button from '@/components/Button.vue';

export default {
  name: 'TotpEnroll',
  components: { Button },
  async mounted() {
    const { data } = await this.totpEnroll();
    this.qrCode = data.qrCode;
    localStorage.setItem('new-account', 'true');
  },
  data() {
    return {
      qrCode: '',
    };
  },
  methods: {
    ...mapActions(['totpEnroll']),
    nextStage() {
      this.$router.push('/totp-verify');
    },
  },
};
</script>

<style scoped></style>
