<template>
  <div class="flex flex-grow items-start fade-in">
    <div class="relative top-0 left-0 w-full h-full">
      <div class="background-wrapper background-wrapper--step2">
        <BackgroundImage class="background" />
      </div>
    </div>
    <div class="w-full mx-8 md:w-5/12 md:mt-32 text-center">
      <h2 class="uppercase text-2xl mb-2">Loading...</h2>
      <p class="uppercase mb-2">It can take up to 20 seconds to create an invoice.</p>
    </div>
  </div>
</template>
<script>
import BackgroundImage from '@/assets/new-report-bg.svg?inline';
import { mapActions } from 'vuex';

export default {
  name: 'Index',
  components: { BackgroundImage },
  async mounted() {
    const { token, period } = this.$route.query;
    const existingToken = localStorage.getItem('orbital-access-token');
    if (existingToken) {
      await this.getSubscriptionUrl(period);
      return;
    }

    if (token) {
      localStorage.setItem('orbital-access-token', token);
      await this.getSubscriptionUrl(period);
    } else {
      await this.$router.push({ path: '/login' });
    }
  },
  methods: {
    ...mapActions(['verifyTotp', 'getSubscriptionUrl']),
  },
};
</script>
<style scoped>
.background {
  @apply max-w-screen-lg w-full h-full;
  animation: spin 120s linear infinite;
  transform-origin: center center;
}
.background-wrapper--step2 {
  transform: translateY(20%) scale(1);
}
</style>
