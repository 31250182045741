<template>
<!--  <div class="flex flex-grow items-start fade-in">-->
<!--    <div class="relative top-0 left-0 w-full h-full">-->
<!--      <div class="background-wrapper background-wrapper&#45;&#45;step2">-->
<!--        <BackgroundImage class="background" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="w-full mx-8 md:w-5/12 md:mt-32 text-center">-->
<!--      <h2 class="uppercase text-2xl mb-2">Loading...</h2>-->
<!--    </div>-->
<!--  </div>-->
  <div>here!!!!!</div>
</template>
<script>
// import BackgroundImage from '@/assets/new-report-bg.svg?inline';
import { mapActions } from 'vuex';

export default {
  name: 'Create',
  // components: { BackgroundImage },
  async mounted() {
    console.log('here');
    const { period } = this.$route.query;
    console.log('period', period);
    await this.getSubscriptionUrl(period);
  },
  methods: {
    ...mapActions(['getSubscriptionUrl']),
  },
};
</script>

<style scoped></style>
